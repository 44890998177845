<template>
  <div>
    <pre>{{ needles }}</pre>
  </div>
</template>

<script>
// import layoutDatabase from "../../layouts/layout-database.vue";
import layoutHome from '../../layouts/layout-home.vue'

export default {
  created() {
    this.$emit('update:layout', layoutHome)
  },
  computed: {
    needles() {
      return this.$store.getters['needles/getNeedles']
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch('needles/fetchNeedles')
    } catch (err) {
      console.log('err :>> ', err)
    }
  }
}
</script>

<style></style>
